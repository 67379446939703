span.p-element.p-dropdown-label.p-inputtext.p-placeholder,
.p-element.p-dropdown-label {
  line-height: 12px !important;
  font-size: 14px;
}

.p-multiselect-label.p-placeholder,
.p-multiselect .p-multiselect-label {
  line-height: 17px !important;
  font-size: 14px;
  color: #6b7280 !important;
}

.p-calendar {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.p-calendar input::placeholder,
.p-calendar input::-ms-input-placeholder {
  color: #6b7280 !important;
}

.p-calendar .p-inputtext {
  font-size: 14px;
  border: none !important;
  height: 42px !important;
  color: #6b7280 !important;
}

.p-dropdown .p-dropdown-panel li.p-dropdown-item:hover {
  background-color: #eedebd !important;
  color: #202020 !important;
}

.p-dropdown-header .p-dropdown-filter-container input.p-dropdown-filter,
.p-multiselect-filter-container input.p-multiselect-filter {
  padding: 5px 10px;
  border: 1px solid #c4c4c4;
}

.p-datatable-table tbody td {
  padding: 5px !important;
  font-size: 12px !important;
}

.p-multiselect .p-multiselect-panel .p-multiselect-header {
  padding: 10px;
}

.p-datatable-thead tr th {
  padding: 5px !important;
}

.p-tree-container li {
  padding: 0px;
}

.p-tree-container li li {
  padding-left: 20px;
}

.p-tree-container li li label {
  font-weight: 400;
  color: #545454;
}

.p-tree-container.p-treenode-selectable {
  padding: 0px;
}

.p-tabview-panel label {
  padding-bottom: 5px;
}

input[type="text"] {
  height: 43px;
  border: 1px solid #ced4da !important;
}

input[type="text"]::placeholder {
  color: #6b7280 !important;
}

.p-tabview-nav a {
  font-weight: 500;
}

.profile_main .inp_w input {
  max-width: 450px;
}

.profile_main .inp_w .flex.flex-col.sm\:flex-row input {
  max-width: 395px;
}

.profile_main .p-tabview-panels {
  overflow: auto;
}

#sidebar-wrapper.fixed {
  z-index: 9;
}

.dashboard-filter .chip-area .chip {
  font-size: 12px;
  line-height: 12px;
}

.drivers-main form .flex-1 {
  max-width: 300px;
}

.status_c {
  width: 8px;
  height: 8px;
}

.tree-n p-treenode {
  font-weight: 600;
}

.tree-n p-treenode label {
  color: #78491f;
}

.p-paginator.p-component button.p-highlight {
  color: #fff;
  background-color: #e0a721;
  border-radius: 50%;
  min-width: 30px;
  height: 30px;
}
// Component-specific responsive styles
@media (max-width: 767px) {
  .mobile {
    padding-right: 15px;
  }

  .p-menu.p-component.p-menu-overlay,
  .rev_ord {
    width: 100% !important;
  }

  .search_b_or form div {
    padding-right: 15px;
  }

  .container,
  .tasks_table .p-datatable-wrapper table,
  table.p-datatable-table {
    width: 1000px;
  }

  .min-h-screen.bg-body {
    width: 265px;
    overflow: auto;
  }

  .rev_ord table {
    width: 400px;
    overflow: auto;
  }

  dashboard-filter {
    padding-top: 15px;
    display: block;
  }

  zone-filter .bg-white.p-4.rounded.shadow-md,
  .drivers-main .bg-white.p-4.rounded.shadow-md,
  reports-version .bg-white.rounded-lg.shadow-md,
  org-filter .bg-white.p-4.rounded.shadow-md {
    padding: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .p-dialog-content .grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .profile_main input,
  .p-password {
    width: 350px;
    max-width: 350px !important;
  }

  .profile_main .flex.flex-col.sm\:flex-row input {
    max-width: 300px !important;
  }

  .profile_main .flex-col {
    flex-direction: row;
  }

  .profile_main .custom-btn,
  .profile_main .custom-btn:last-child {
    margin-bottom: 10px !important;
    width: inherit;
  }

  subac-permission .custom-btn {
    width: fit-content;
    display: inline-block;
  }

  .grid_c2_r.grid.grid-cols-4 {
    display: block;
  }

  .bg-white.shadow-lg.mb-6.rounded-lg.p-5 {
    padding: 10px !important;
  }

  .d-block-r {
    display: block;
  }

  .d-block-r .text-left {
    padding-bottom: 20px;
  }
}
// Component-specific responsive styles
@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
  dashboard-filter {
    padding-top: 15px;
    display: block;
  }

  .profile_main input,
  .p-password {
    width: 350px;
    max-width: 350px !important;
  }

  .profile_main .flex.flex-col.sm\:flex-row input {
    max-width: 300px !important;
  }

  .profile_main .flex-col {
    flex-direction: row;
  }

  .profile_main .custom-btn,
  .profile_main .custom-btn:last-child {
    margin-bottom: 10px !important;
    width: inherit;
  }

  subac-permission .custom-btn {
    width: fit-content;
    display: inline-block;
  }

  .btn-cntnr button[type="button"] {
    margin-bottom: 10px !important;
  }

  p-tree .p-tree.p-component {
    padding: 0;
  }

  .rev_ord.w-50 {
    width: 80% !important;
  }

  .grid_c2_r.grid.grid-cols-4 {
    display: block;
  }
}
