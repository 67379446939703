/**
 * Global Styles
 *
 * This file contains global styles and imports other style files.
 * You can now use the variables defined in _settings.scss.
 */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
/* Import settings */
// @import "../public/assets/styles/settings";
@import "primeicons/primeicons.css";

@import "../public/assets/styles/globals.scss";
@import "../public/assets/styles/component.scss";
@import "../public/assets/styles/utilities.scss";
@import "../public/assets/styles/prime-ng.scss";

/**
 * Body styles
 */
body {
  font-family: $font-primary;
  background-color: $body-color;
  color: $text-color;
}

/**
 * Auth layout styles
 */
body.auth-layout {
  background-color: $auth-body-color !important;
}

/**
 * Custom scrollbar styles
 */
::-webkit-scrollbar {
  width: 12px; /* Adjust the width of the scrollbar */
  height: 12px; /* Adjust the height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: $body-color; /* Color of the scrollbar track */
  border-radius: 10px; /* Optional: add some border radius for rounded corners */
}

::-webkit-scrollbar-thumb {
  background-color: $button-color; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: add some border radius for rounded corners */
  border: 3px solid $body-color; /* Optional: add some padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: darken(
    $button-color,
    10%
  ); /* Color of the scrollbar thumb on hover */
}

::-webkit-scrollbar-thumb:active {
  background-color: lighten(
    $button-color,
    10%
  ); /* Color of the scrollbar thumb on active */
}

/**
 * Global button styles
 */
.custom-btn {
  background-color: $button-color !important;
  border-color: $button-color !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;

  &:hover {
    background-color: $button-color-hover !important; /* Darker shade for hover effect */
  }

  &:focus {
    outline: 2px solid none !important; /* Highlight the button on focus */
    outline-offset: 2px !important;
  }
}
/* Responsive button styles */
@media (max-width: 768px) {
  .custom-btn {
    padding: 8px 16px !important; /* Reduced padding for smaller screens */
    width: 100%; /* Full width for better stacking */
    margin-bottom: 10px !important; /* Space between buttons */
  }

  .custom-btn:last-child {
    margin-bottom: 0 !important; /* Remove margin from the last button */
  }
}

/**
 * Heading styles
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

/**
 * Spacing utilities
 */
.container {
  padding: 20px;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

/**
 * Text Highlight
 */
::selection {
  background-color: $text-selection-color; /* Complementary color for #fcba2b */
  color: $table-header; /* Optional: Change the text color to white for better contrast */
}

::-moz-selection {
  background-color: $text-selection-color; /* Complementary color for #fcba2b */
  color: $table-header; /* Optional: Change the text color to white for better contrast */
}

/**
 * Responsive design
 */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .custom-btn {
    padding: 8px 16px;
  }
}

/**
 * PrimeNG checkbox styles
 */
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $button-color !important;
  background: #fff !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: $button-color !important;
}

/**
 * Input focus styles
 */
textarea:focus,
select:focus,
input[type="checkbox"]:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(218, 179, 37, 0.8) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(218, 190, 8, 0.6) !important;
  outline: 0 none !important;
}

/**
 * PrimeNG checkbox focus styles
 */
.p-checkbox-box {
  outline: none !important; /* Remove default focus outline */
  box-shadow: 0 0 5px rgba(218, 190, 8, 0.6) !important; /* Your box-shadow style */
  transition: all 0.2s ease !important; /* Transition for box-shadow */
}

.p-checkbox-box:focus,
.p-checkbox-box:hover {
  transition: none !important; /* Remove transitions on hover and focus */
  border-color: rgba(218, 179, 37, 0.8) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(218, 190, 8, 0.6) !important;
  outline: 0 none !important; /* Ensure outline is removed */
}

/**
 * Optional: Add focus styles to checkbox container
 */
.p-checkbox.p-focus {
  box-shadow: 0 0 5px rgba(218, 190, 8, 0.6); /* Yellow glow */
}

.form-check-input:focus {
  outline: 2px solid #fcba2b; /* Yellow outline */
  box-shadow: 0 0 5px rgba(218, 190, 8, 0.6); /* Yellow glow */
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #fcba2b;
}

/**
 * Remove default focus outlines from all focusable elements
 */
*,
*::before,
*::after {
  box-sizing: border-box; /* Ensures consistent box model across browsers */
}

*:focus {
  outline: none;
}

/**
 * Remove transitions on checked and unchecked states
 */
.p-checkbox-box input[type="checkbox"]:checked {
  transition: none !important;
  /* ... other checked styles ... */
}

.p-checkbox-box input[type="checkbox"]:not(:checked) {
  transition: none !important;
  /* ... other unchecked styles ... */
}

/**
 * Global link styles
 */
a {
  cursor: pointer;
}

/**
 * Form control styles
 */
.form-control {
  border-radius: 3px !important;
}

/**
 * Remove blue outline on hover/focus for PrimeNG input text
 */
.p-inputtext:enabled:hover {
  border-color: $button-color !important;
}

/**
 * PrimeNG TabView styles
 */
.p-tabPanel {
  color: red !important;
}

/**
 * Style for Tab Header Anchors
 */
.p-tabview .p-tabview-nav .p-tabview-nav-link {
  color: $button-color !important; /* Text color for non-selected tabs */
  border-bottom: none !important; /* Remove default border */
  text-decoration: none;
}

/**
 * Hover Effect for Tab Headers
 */
.p-tabview .p-tabview-nav .p-tabview-nav-link:hover {
  color: $button-color !important; /* Text color on hover */
  background: $auth-body-color !important; /* Ensure no background color on hover */
}

/**
 * Focus State for Tab Headers
 */
.p-tabview .p-tabview-nav .p-tabview-nav-link:focus {
  // outline: none !important; /* Remove default focus outline */
  // box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5) !important; /* Custom focus outline */
  background: $auth-body-color !important; /* Red background for the selected tab */
  color: $button-color !important; /* White text for the selected tab */
  border-bottom: 2px solid $button-color !important;
}

/**
 * Remove Default Border Under Tabs
 */
.p-tabview .p-tabview-nav {
  border-bottom: none !important; /* Remove default border for the tab navigation */
}
/**
 * Style for selected Tab Header
 */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: $button-color !important; /* Text color for the selected tab */
  background: $auth-body-color !important; /* Background color for the selected tab */
  border-bottom: 2px solid $button-color !important; /* Bottom border for the selected tab */
}

/* Apply full width specifically to the p-password component */
.p-password {
  width: 100% !important;
}

/* If you need to specifically adjust the input field within p-password */
.p-password .p-password-input {
  width: 100% !important;
  height: 45px !important;
  padding-left: 20px;
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: $auth-body-color;
  background: #fff9ec !important;
}
.form-label {
  color: $button-color !important;
}
.p-inputgroup:focus {
  outline: none !important; /* Remove default focus outline */
  box-shadow: 0 0 5px rgba(218, 190, 8, 0.6) !important; /* Your box-shadow style */
  transition: all 0.2s ease !important; /* Transition for box-shadow */
}

$text-selection-color: #eedebd;
$button-color: #fcba2b;

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  outline-color: transparent;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: $button-color;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $text-selection-color;
  border-color: $button-color;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: $button-color;
  background: $button-color;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: $button-color;
  background: $button-color;
  color: #ffffff;
}

.p-radiobutton.p-variant-filled .p-radiobutton-box {
  background-color: $text-selection-color;
}

.p-radiobutton.p-variant-filled .p-radiobutton-box:not(.p-disabled):hover {
  background-color: $text-selection-color;
}

.p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight {
  background: $button-color;
}

.p-radiobutton.p-variant-filled
  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: $button-color;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: $button-color;
}
.p-datatable .p-datatable-tbody > tr {
  color: $text-color2 !important;
}
.p-dialog .p-dialog-header .p-dialog-title {
  color: $table-header;
}
.p-dialog .p-dialog-content {
  color: $text-color2;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background-color: transparent;
  color: $text-color2;
}
.p-button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.p-dialog-header-icons {
  display: none !important;
}
.p-inputMask {
  width: 100% !important;
}
.p-dropdown {
  width: 100% !important; /* Full width for dropdowns */
  border-radius: 5px !important; /* Consistent border radius */
  border: 1px solid #ced4da !important; /* Border color */
  background: #ffffff !important; /* Background color */
  color: $text-color !important; /* Text color */
  transition: border-color 0.2s ease, box-shadow 0.2s ease !important; /* Smooth transitions */
  height: 42px !important;
  margin-top: 5px;

  &:focus {
    border-color: rgba(
      218,
      179,
      37,
      0.8
    ) !important; /* Border color on focus */
    box-shadow: 0 0 5px rgba(218, 190, 8, 0.6) !important; /* Glow effect on focus */
    outline: none !important; /* Remove default focus outline */
  }

  &.p-focus {
    box-shadow: 0 0 5px rgba(218, 190, 8, 0.6) !important; /* Custom glow effect */
  }

  // Dropdown items
  .p-dropdown-panel {
    background-color: #ffffff !important; /* Panel background color */
    border-radius: 3px !important; /* Panel border radius */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important; /* Panel shadow */

    .p-dropdown-item {
      color: $text-color !important; /* Item text color */
      padding: 10px !important; /* Item padding */
      cursor: pointer; /* Pointer cursor for items */
      transition: background-color 0.2s ease !important; /* Smooth background transition */

      &:hover {
        background-color: $text-selection-color !important; /* Background color on hover */
        color: #ffffff !important; /* Text color on hover */
      }
    }
  }
}

/* Dropdown for disabled state */
.p-dropdown:disabled {
  background-color: #f8f9fa !important; /* Light gray background */
  color: $text-color2 !important; /* Disabled text color */
  cursor: not-allowed; /* Change cursor for disabled dropdowns */
}
/* Custom styles for PrimeNG datatable striped rows */
.p-datatable-striped .p-row:nth-child(odd) {
  background-color: #f9f9f9 !important; /* Light gray background for odd rows */
}

.p-datatable-striped .p-row:nth-child(even) {
  background-color: #8c1919 !important; /* White background for even rows */
}
/* Custom styles for PrimeNG MultiSelect component */
.p-multiselect {
  margin-top: 5px;
  height: 42px !important;
  width: 100% !important; /* Full width for better usability */
  border-radius: 5px !important; /* Consistent border radius */
  background-color: #ffffff; /* White background */
  border: 1px solid #ced4da; /* Border color to match your theme */
  transition: border-color 0.2s ease; /* Smooth transition for border color */

  &:focus {
    border-color: rgba(218, 190, 8, 0.8) !important; /* Focus border color */
    box-shadow: 0 0 0 0.2rem $text-selection-color; /* Focus shadow effect */
    outline: none !important; /* Remove default outline */
  }

  /* Style for the selected items area */
  .p-multiselect-label {
    color: $text-color; /* Text color for selected items */
    padding: 10px; /* Padding for label */
  }

  /* Style for the dropdown panel */
  .p-multiselect-panel {
    background-color: $auth-body-color; /* Background for dropdown */
    border-radius: 5px; /* Consistent border radius */
    border: 1px solid $button-color; /* Border color to match theme */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow for dropdown */
  }

  /* Style for the items in the dropdown */
  .p-multiselect-item {
    color: $text-color2; /* Text color for items */
    padding: 10px; /* Padding for items */
    transition: background-color 0.2s; /* Smooth transition for hover effect */

    &:hover {
      background-color: $button-color; /* Background color on hover */
      color: white; /* Text color on hover */
    }

    &.p-highlight {
      background-color: $button-color; /* Highlight color for selected items */
      color: white; /* Text color for highlighted items */
    }
  }
}
/**
 * PrimeNG Calendar Styles
 */
.p-calendar {
  // Overall calendar styles
  width: 100%; // Ensure full width
  border-radius: $border-radius; // Use the defined border radius
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); // Add subtle shadow for depth
  margin-top: 4px;
  // Input field styles
  .p-inputtext {
    border: 1px solid #ced4da; // Border color to match theme
    border-radius: $border-radius; // Match border radius
    height: 45px; // Set height for uniformity
    padding-left: 10px; // Left padding for spacing
    transition: border-color 0.2s ease; // Smooth transition for border color

    &:focus {
      border-color: rgba(218, 190, 8, 0.8) !important; // Focus color
      box-shadow: 0 0 5px rgba(218, 190, 8, 0.6); // Add focus shadow
      outline: none !important; // Remove default outline
    }
  }

  // Calendar panel styles
  .p-datepicker {
    border-radius: $border-radius; // Rounded corners for the panel
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15); // Shadow for depth
    background-color: $body-color; // Match body background color

    // Header styles
    .p-datepicker-header {
      background-color: $button-color; // Header background
      color: #ffffff; // Header text color
      border-radius: $border-radius; // Match border radius

      // Month/year navigation styles
      .p-datepicker-prev,
      .p-datepicker-next {
        color: #ffffff; // Navigation arrow colors
      }

      // Title styles
      .p-datepicker-title {
        font-weight: bold; // Bold title
      }
    }

    // Day styles
    .p-datepicker-content {
      .p-datepicker-calendar {
        // Weekday styles
        .p-datepicker-weekday {
          color: $button-color; // Weekday color
        }

        // Day styles
        .p-datepicker-day {
          border-radius: $border-radius; // Rounded corners
          transition: background-color 0.2s ease; // Smooth background transition

          &:hover {
            background-color: lighten($button-color, 10%); // Hover effect
          }

          // Selected day styles
          &.p-highlight {
            background-color: $button-color; // Highlighted day color
            color: #ffffff; // Highlighted day text color
          }
        }
      }
    }
  }
}
/* Ultra-small overall calendar popup size */
.p-datepicker {
  width: auto !important; /* Further reduce the width */
  height: auto !important; /* Adjust height automatically */
  font-size: 10px !important; /* Reduce the overall font size */
}
.p-datepicker-title {
  font-size: 12px !important;
}
/* Smaller calendar cells (dates) */
.p-datepicker-calendar td {
  width: 20px !important; /* Smaller width for each day cell */
  height: 20px !important; /* Smaller height for each day cell */
  font-size: 12px !important; /* Tiny font size for date numbers */
  padding: 2px !important; /* Reduce padding inside the cells */
}

/* Smaller header (Month/Year display) */
.p-datepicker-header {
  padding: 2px 4px !important; /* Very minimal padding */
  font-size: 10px !important; /* Small header text size */
}

/* Smaller next/previous buttons */
.p-datepicker-next,
.p-datepicker-prev {
  font-size: 10px !important; /* Tiny arrow buttons */
  width: 15px !important; /* Reduce width of buttons */
  height: 15px !important; /* Reduce height of buttons */
}

/* Smaller Month/Year dropdowns */
.p-datepicker-title select {
  font-size: 8px !important; /* Very small font size for dropdowns */
  padding: 1px 2px !important; /* Minimal padding for dropdowns */
}

/* Adjust the time picker if used */
.p-timepicker .p-inputtext {
  width: 40px !important; /* Smaller time input width */
  font-size: 8px !important; /* Smaller font size for time input */
}

/* Smaller today/clear buttons */
.p-datepicker-buttonbar button {
  font-size: 10px !important; /* Smaller font size for button text */
  padding: 2px 5px !important; /* Minimal padding for buttons */
}

header nav .text-lg.font-semibold {
  font-size: 14px;
}
header nav .text-sm {
  font-size: 0.75rem;
  line-height: 1rem;
}
header .relative.flex.items-center span.text-lg.font-semibold {
  font-size: 14px;
}
.p-menuitem-content a {
  font-size: 14px;
}
table tbody.p-datatable-tbody td i.cursor-pointer:hover {
  opacity: 0.7;
}
.p-dialog-resizable.p-dialog-draggable .overflow-auto {
  border: 1px solid #ebebeb;
}
.text-4xl {
  font-size: 1.6rem;
}
.drivers-main .custom-btn {
  background-color: #e6b345 !important;
  border-color: #e6b345 !important;
}
.def_img {
  background: #f5cd42 url(/assets/images/profile-user.png) no-repeat 0 0 / 100%;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  display: block;
}
.def_img img {
  width: 100%;
  height: 100%;
}
