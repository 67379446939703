
input::placeholder,
input::-ms-input-placeholder {
  color: #6b7280 !important;
}

.loader {
  width: 50px !important;
  height: 50px !important;
}

.overlay {
  z-index: 1000 !important;
}

.text-button {
  color: rgb(52 52 52) !important;
}

nav ul li {
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  margin-bottom: 5px;
}

nav ul li:hover,
nav ul li:active {
  border-radius: 4px;
  border: 1px solid #969696;
}

nav ul li div {
  border-radius: 4px;
}

nav ul ul {
  padding: 0;
  margin: 0;
}

nav ul li li,
nav ul li li:hover {
  border: none;
}

nav ul li li:hover {
  background-color: rgb(255 234 191) !important;
}

.text-red-500,
.p-dropdown-empty-message {
  font-size: 12px;
}

input,
table td,
button {
  font-size: 14px !important;
}

.left_menu ul ul {
  padding: 0;
  margin: 0;
}

.left_menu ul ul li {
  margin-bottom: 0;
  margin-top: 1px;
}

.text-3xl {
  font-size: 1.5rem;
}

.text-2xl {
  font-size: 1.2rem;
}

.text-xl {
  font-size: 1rem;
}

ul.space-y-2 li {
  box-shadow: none !important;
}

footer {
  width: calc(98vw - 16rem) !important;
}

header img {
  padding-left: 12px !important;
}

.font-semibold,
.font-bold {
  font-weight: 500;
}

.un_inv span {
  padding-right: 7px;
}

@-moz-document url-prefix() {
  body,
  .overflow-auto {
    scrollbar-color: rgb(246, 183, 9) rgb(220, 220, 220);
    scrollbar-width: thin;
  }

  /**
   * Custom scrollbar styles
   */
  body::-webkit-scrollbar,
  .overflow-auto::-webkit-scrollbar {
    width: 5px !important; /* Adjust the width of the scrollbar */
    height: 5px !important; /* Adjust the height of the scrollbar */
  }

  body::-webkit-scrollbar-track,
  body::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
  }
}
// Utility responsive styles
@media (max-width: 767px) {
  .container-fluid,
  .tasks_table,
  .search_b_or,
  sub-account {
    overflow: auto;
  }

  footer {
    width: 88vw !important;
    font-size: 12px;
  }

  footer .justify-center {
    justify-content: left !important;
    padding-left: 15px;
  }

  .text-center.text-gray-600.text-sm.p-3.bg-authBody {
    text-align: left !important;
  }

  .p-paginator.p-component {
    padding: 0;
  }

  .text-2xl {
    font-size: 1rem;
  }

  p-radiobutton {
    padding-right: 5px;
  }

  .p-paginator.p-component button {
    min-width: 22px;
  }
}
// Utility responsive styles
@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
  .container,
  .tasks_table .p-datatable-wrapper table,
  .p-datatable-wrapper table {
    width: 1600px !important;
  }

  table.p-datatable-table {
    width: 100%;
  }

  .min-h-screen.bg-body {
    width: 100%;
    overflow: auto;
  }

  .container-fluid,
  .tasks_table,
  .search_b_or,
  sub-account {
    overflow: auto;
  }

  footer {
    width: calc(100vw - 5rem) !important;
  }

  .p-paginator.p-component button {
    min-width: 40px;
  }
}

@media (min-width: 1537px) {
  .container {
    max-width: 100%;
  }
}
