@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

// Color Variables

$auth-body-color: #fff7e7;
$body-color: #f2f3f9;
$button-color: #fcba2b;
$button-color-hover: #e0a721;
$text-color: #3f4047;
$text-color2: #696a74;
$table-header: #78491f;
$text-selection-color: #eedebd;

// Font Variables
$font-primary: "Poppins", sans-serif;
$font-secondary: "Open Sans", sans-serif;

// Font Sizes
$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.875rem;

// Spacing Variables
$spacer: 1rem;
$spacer-sm: 0.5rem;
$spacer-lg: 2rem;

// Border Radius
$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;

$header-height: 70px; // Adjust as needed
$footer-height: 60px; // Adjust as needed

// Optional Global Font Settings (if needed)
body {
  font-family: $font-primary;
  font-size: $font-size-base;
  color: $text-color;
  background-color: $auth-body-color;
}
